import { flowRight, isEmpty, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import {
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_POST_EDIT,
  isMembersOnly,
} from '@wix/communities-forum-client-commons';
import AccountSuspended from '../../components/account-suspended';
import AnimatedLoader from '../../components/animated-loader';
import Button from '../../components/button';
import EmptyStates from '../../components/empty-states';
import PostPageSidebar from '../../components/post-page-sidebar';
import PostContent from '../../components/post-content';
import PostHeader from '../../components/post-header';
import PostPageLayout from '../../components/post-page-layout';
import PostSubTitle from '../../components/post-sub-title';
import PostPageStats from '../../components/post-page-stats';
import PostTitle from '../../components/post-title';
import { HorizontalSeparator } from '../../components/separator';
import FooterPosts from '../../components/footer-posts';
import { PostSingleRowFooter } from '../../components/post-footer/post-single-row-footer';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import {
  getCategoryBySlug,
  getMarkedCommentConfig,
} from '../../../common/selectors/categories-selectors';
import { getIsPostLoaded } from '../../selectors/is-loaded-selectors';
import { getIsPostLoading } from '../../selectors/is-loading-selectors';
import { getPostBySlug } from '../../selectors/post-selectors';
import withAuth from '../../hoc/with-auth';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getRouteParams, getPreviousMatches } from '../../../common/router/router-selectors';
import { getDeepLinkParams } from '../../services/get-deep-link-params';

import { getIsFooterPostsEnabled } from '../../selectors/app-settings-selectors';

import styles from './post-page-desktop.scss';
import { PostBreakpoints, ForumWidthContext } from '../../components/responsive-listener';

import PostPageWixComments from '../wix-comments/post-page-wix-comments';

export class PostPage extends Component {
  static contextType = ForumWidthContext;
  constructor(props) {
    super(props);
    this.postContainerRef = React.createRef();
  }

  componentDidMount = () => {
    const { commentToFocusId } = this.props;
    if (!commentToFocusId && this.postContainerRef.current) {
      this.postContainerRef.current.focus({ preventScroll: true });
    }
  };

  componentWillUnmount = () => {
    if (!isEmpty(this.props.category)) {
      this.props.emitClosePost(this.props.post._id);
    }
  };

  renderPost = () => {
    const {
      category,
      post,
      isAnimated,
      borderWidth,
      titleFontClassName,
      postOwnerBadges,
      isForumSmaller,
    } = this.props;

    const postContainerClassName = classNames(
      styles.postContainer,
      styles.withComments,
      'forum-card-border-color',
      'forum-text-color',
    );

    return (
      <React.Fragment>
        <main
          ref={this.postContainerRef}
          tabIndex="-1"
          className={postContainerClassName}
          style={{ borderWidth }}
          aria-labelledby={`post-header-${post.slug}`}
        >
          <div
            className={styles.postContentWrapper}
            data-hook={`post-page__post ${post.isSubscribed ? 'subscribed' : 'not_subscribed'}`}
          >
            <PostHeader
              type={PostHeader.POST_PAGE_NEW}
              category={category}
              post={post}
              isAnimated={isAnimated}
              showDateBelowAvatar
              badge={postOwnerBadges}
              showEditedDate
              showMoreButton
            />
            <PostTitle
              className={titleFontClassName}
              type={PostTitle.POST_PAGE}
              title={post.title || ''}
              component="h1"
              id={`post-header-${post.slug}`}
            />
            <PostSubTitle post={post} type="postPage" category={category} showCategoryLink={true} />
            <div className={styles.postContent} data-hook="post-content-container">
              <PostContent post={post} type={PostContent.POST_PAGE} />
            </div>
            <div
              className={classNames(styles.postStatsContainer, {
                [styles.hidden]: !isForumSmaller,
              })}
            >
              <PostPageStats
                viewCount={post.viewCount}
                commentCount={post.totalComments - post.totalReplies}
                replyCount={post.totalReplies}
                isAnimated={isAnimated}
                postType={post.postType}
                postId={post._id}
              />
            </div>
            <PostSingleRowFooter category={category} post={post} origin="postPage" />
          </div>
        </main>
      </React.Fragment>
    );
  };

  renderEmpty = () => {
    const { t, goBack } = this.props;
    return (
      <EmptyStates
        className={styles.emptyState}
        title={t('post-page.not-found-title')}
        content={t('post-page.not-fount-content')}
      >
        <Button onClick={goBack}>{t('post-page.back-to-forum')}</Button>
      </EmptyStates>
    );
  };

  render = () => {
    const {
      isBlocked,
      category,
      post,
      contentFontClassName,
      isLoading,
      isLoaded,
      commentToFocusId,
      isFooterPostsEnabled,
      markedCommentConfig,
    } = this.props;

    const width = this.context;
    const isSmallerForum = width < PostBreakpoints.md;

    if (isEmpty(post) && !isLoading) {
      return this.renderEmpty();
    }

    if (!isEmpty(category) && isMembersOnly(category) && isBlocked) {
      return <AccountSuspended className={styles.emptyState} />;
    }

    return (
      <PostPageLayout
        className={classNames(styles.postPage, contentFontClassName, 'forum-card-background-color')}
      >
        <div className={classNames(styles.postWithSidebar)}>
          <AnimatedLoader
            className={classNames(styles.innerContainer, {
              [styles.innerContainerWithoutSidebar]: isSmallerForum,
            })}
            isLoading={isLoading && !isLoaded && isEmpty(post)}
          >
            {this.renderPost()}
            <HorizontalSeparator className={styles.divider} />
            <PostPageWixComments
              category={category}
              post={post}
              hideHeaderDivider
              commentToFocusId={commentToFocusId}
              markedCommentConfig={markedCommentConfig}
            />
          </AnimatedLoader>
          {!isSmallerForum && (
            <aside className={styles.sidebar}>
              <PostPageSidebar post={post} />
            </aside>
          )}
        </div>
        {isSmallerForum && isFooterPostsEnabled && (
          <div className={styles.footerPosts} data-hook="footer-posts-wrapper">
            <FooterPosts type={FooterPosts.TYPE_RELATED_POSTS} isLoading={isLoading} />
          </div>
        )}
      </PostPageLayout>
    );
  };
}

PostPage.propTypes = {
  category: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  page: PropTypes.number,
  commentToFocusId: PropTypes.string,
  emitClosePost: PropTypes.func.isRequired,
  t: PropTypes.func,
  isBlocked: PropTypes.bool,
  isAnimated: PropTypes.object,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isFooterPostsEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const post = getPostBySlug(state, params.postSlug) || {};
  const category = getCategoryBySlug(state, params.categorySlug) || {};
  const page = parseInt(params.page, 10) || 1;
  const { commentToFocusId } = getDeepLinkParams(params.deepLinkData);
  const prevMatch = getPreviousMatches(state)[1]; // take 1st member, since 0 is the current route
  const prevRoute = get(prevMatch, 'route');

  return {
    isAnimated: [
      ROUTE_CREATE_POST,
      ROUTE_CREATE_QUESTION,
      ROUTE_CATEGORY_CREATE_POST,
      ROUTE_CATEGORY_CREATE_QUESTION,
      ROUTE_POST_EDIT,
    ].includes(prevRoute),
    category,
    post,
    isLoading: getIsPostLoading(state, params.postSlug),
    isLoaded: getIsPostLoaded(state, params.postSlug),
    page,
    commentToFocusId,
    emitClosePost: actions.emitClosePost,
    isFooterPostsEnabled: getIsFooterPostsEnabled(state, host.style),
    markedCommentConfig: getMarkedCommentConfig(category),
    goBack: () => actions.goBack(),
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [
    REDUCERS.BASIC_PARAMS,
    REDUCERS.ROUTER,
    REDUCERS.CATEGORIES,
    REDUCERS.POSTS,
    REDUCERS.IS_LOADING,
    REDUCERS.IS_LOADED,
    REDUCERS.PAGINATION,
  ]),
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslate,
)(PostPage);
