import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PostLink from '../link/post-link';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './post-page-sidebar-new-posts.scss';
import { SIDEBAR } from '../../constants/bi-locations';
import NewContentIndicator from '../new-content-indicator';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import { connect } from '../../../common/components/runtime-context';

const PostPageSidebarNewPosts = React.memo(
  ({ posts, showRelatedPosts, borderWidth, t, getIsPostNew, fetchPost }) => {
    return posts.length === 0 ? null : (
      <section
        className={classNames(styles.container, 'forum-text-color')}
        style={{ borderWidth }}
        data-hook="sidebar-posts"
      >
        <span className={styles.title}>
          {t(
            showRelatedPosts
              ? 'post-page-sidebar-new-posts.related-posts'
              : 'post-page-sidebar-new-posts.title',
          )}
        </span>
        <ul>
          {posts.map((post) => (
            <li className={styles.post}>
              <PostLink
                className={classNames(
                  styles.postLink,
                  'forum-text-color',
                  'forum-link-hover-color',
                )}
                post={post}
                biLocation={SIDEBAR}
                data-hook="post-title"
                onClick={() => fetchPost(post.slug)}
              >
                {post.title}
              </PostLink>
              <NewContentIndicator hideNumber hideBackground count={getIsPostNew(post._id) && 1} />
            </li>
          ))}
        </ul>
      </section>
    );
  },
);

PostPageSidebarNewPosts.propsTypes = {
  posts: PropTypes.array,
  borderWidth: PropTypes.number.isRequired,
  getIsPostNew: PropTypes.func,
  fetchPost: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  return {
    fetchPost: (postSlug) => actions.fetchPostPageData(postSlug, 1),
  };
};

export default flowRight(
  withTranslate,
  withCardBorderWidth,
  connect(mapRuntimeToProps),
)(PostPageSidebarNewPosts);
