import { flowRight, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { isMembersOnly } from '@wix/communities-forum-client-commons';
import AccountSuspended from '../../components/account-suspended';
import AnimatedLoader from '../../components/animated-loader';
import Button from '../../components/button';
import EmptyStates from '../../components/empty-states';
import Link from '../../components/link/internal-link';
import PostPageCardHeader from '../../components/card/post-page-card-header';
import PostContent from '../../components/post-content';
import PostPageLayout from '../../components/post-page-layout';
import PostTitle from '../../components/post-title';
import { HorizontalSeparator } from '../../components/separator';
import FooterPosts from '../../components/footer-posts';
import {
  getCategoryBySlug,
  getMarkedCommentConfig,
} from '../../../common/selectors/categories-selectors';
import { getIsPostLoaded } from '../../selectors/is-loaded-selectors';
import { getIsPostLoading } from '../../selectors/is-loading-selectors';
import { getPostBySlug } from '../../selectors/post-selectors';
import withAuth from '../../hoc/with-auth';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getRouteParams } from '../../../common/router/router-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getDeepLinkParams } from '../../services/get-deep-link-params';
import { getIsFooterPostsEnabled } from '../../selectors/app-settings-selectors';
import { getCurrentUser } from '../../../common/store/current-user/current-user-selectors';
import styles from './post-page-mobile.scss';
import { PostMultiRowFooter } from '../../components/post-footer/post-multi-row-footer';

import PostPageWixComments from '../wix-comments/post-page-wix-comments';

export class PostPage extends Component {
  constructor(props) {
    super(props);
    this.postContainerRef = React.createRef();
  }

  componentDidMount = () => {
    const { commentToFocusId } = this.props;
    if (!commentToFocusId && this.postContainerRef.current) {
      this.postContainerRef.current.focus({ preventScroll: true });
    }
  };

  componentWillUnmount = () => {
    if (!isEmpty(this.props.category)) {
      this.props.emitClosePost(this.props.post._id);
    }
  };

  renderPost = () => {
    const { category, post, borderWidth, titleFontClassName } = this.props;

    const postContainerClassName = classNames(
      styles.postContainer,
      'forum-card-border-color',
      'forum-text-color',
    );

    return (
      <main
        ref={this.postContainerRef}
        tabIndex="-1"
        className={postContainerClassName}
        style={{ borderWidth }}
        aria-labelledby={`post-header-${post.slug}`}
      >
        <div data-hook={`post-page__post ${post.isSubscribed ? 'subscribed' : 'not_subscribed'}`}>
          <div className={styles.postHeader}>
            <PostPageCardHeader post={post} />
          </div>
          <PostTitle
            className={titleFontClassName}
            type={PostTitle.POST_PAGE}
            title={post.title || ''}
            component="h1"
            id={`post-header-${post.slug}`}
          />
          <div className={styles.postContent} data-hook="post-content-container">
            <PostContent post={post} type={PostContent.POST_PAGE} />
          </div>
          <div className={styles.extraFooterSpacing}>
            <PostMultiRowFooter
              post={post}
              category={category}
              origin="postPageMobile"
              shouldFocusCommentBox={true}
            />
          </div>
        </div>
      </main>
    );
  };

  renderEmpty = () => {
    const { t } = this.props;
    return (
      <EmptyStates
        className={styles.emptyState}
        title={t('post-page.not-found-title')}
        content={t('post-page.not-fount-content')}
      >
        <Button component={Link} to="/">
          {t('post-page.back-to-forum')}
        </Button>
      </EmptyStates>
    );
  };

  render = () => {
    const {
      isBlocked,
      category,
      post,
      contentFontClassName,
      isLoading,
      isLoaded,
      commentToFocusId,
      isFooterPostsEnabled,
      markedCommentConfig,
    } = this.props;

    if (isEmpty(post) && !isLoading) {
      return this.renderEmpty();
    }

    if (!isEmpty(category) && isMembersOnly(category) && isBlocked) {
      return <AccountSuspended className={styles.emptyState} />;
    }

    return (
      <PostPageLayout
        className={classNames(
          styles.postPage,
          contentFontClassName,
          'forum-card-background-color',
          'forum-card-border-color',
        )}
      >
        <AnimatedLoader
          className={classNames(styles.innerContainer)}
          isLoading={isLoading && !isLoaded && isEmpty(post)}
        >
          {this.renderPost()}
          <PostPageWixComments
            post={post}
            category={category}
            commentToFocusId={commentToFocusId}
            markedCommentConfig={markedCommentConfig}
          />
        </AnimatedLoader>
        {isFooterPostsEnabled && (
          <div className={styles.footerPosts} data-hook="footer-posts-wrapper">
            <HorizontalSeparator isLarge />
            <FooterPosts type={FooterPosts.TYPE_RELATED_POSTS} />
          </div>
        )}
      </PostPageLayout>
    );
  };
}

PostPage.propTypes = {
  category: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  commentToFocusId: PropTypes.string,
  emitClosePost: PropTypes.func.isRequired,
  t: PropTypes.func,
  isBlocked: PropTypes.bool,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isFooterPostsEnabled: PropTypes.bool,
  currentUser: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const post = getPostBySlug(state, params.postSlug) || {};
  const category = getCategoryBySlug(state, params.categorySlug) || {};
  const { commentToFocusId } = getDeepLinkParams(params.deepLinkData);

  return {
    category,
    post,
    isLoading: getIsPostLoading(state, params.postSlug),
    isLoaded: getIsPostLoaded(state, params.postSlug),
    commentToFocusId,
    emitClosePost: actions.emitClosePost,
    isFooterPostsEnabled: getIsFooterPostsEnabled(state, host.style),
    currentUser: getCurrentUser(state),
    markedCommentConfig: getMarkedCommentConfig(category),
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [
    REDUCERS.BASIC_PARAMS,
    REDUCERS.ROUTER,
    REDUCERS.CATEGORIES,
    REDUCERS.POSTS,
    REDUCERS.IS_LOADING,
    REDUCERS.IS_LOADED,
    REDUCERS.PAGINATION,
  ]),
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslate,
)(PostPage);
