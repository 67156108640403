import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { CommentIcon } from '../icons/comment-icon';
import styles from './post-page-sidebar-stats.scss';
import { ViewCountIcon } from '../icons/view-count-icon';
import CounterNumber from '../counter-number';
import withCardBorderWidth from '../../hoc/with-card-border-width';

const PostPageSidebarStats = ({
  viewCount,
  totalComments,
  isViewsCountEnabled,
  postId,
  borderWidth,
}) => {
  return (
    <section className={styles.container} style={{ borderWidth }}>
      <div className={styles.iconColumn}>
        {isViewsCountEnabled && (
          <div className={styles.row}>
            <ViewCountIcon className="forum-icon-fill" />
          </div>
        )}
        <div className={styles.row}>
          <CommentIcon className="forum-icon-stroke" />
        </div>
      </div>
      <div className={classNames(styles.column, 'forum-text-color')}>
        {isViewsCountEnabled && (
          <div className={styles.row} data-hook="post-page-sidebar-stats__view-count">
            <CounterNumber
              tKey="post-page-sidebar-stats.view-count"
              initialValue={viewCount}
              entityId={postId}
              viewCount
            />
          </div>
        )}
        <div className={styles.row} data-hook="post-page-sidebar-stats__comment-count">
          <CounterNumber
            tKey="post-page-sidebar-stats.comment-count"
            initialValue={totalComments}
            entityId={postId}
            totalComments
          />
        </div>
      </div>
    </section>
  );
};

PostPageSidebarStats.propTypes = {
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  totalReplies: PropTypes.number.isRequired,
  postType: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  isViewsCountEnabled: PropTypes.bool.isRequired,
  borderWidth: PropTypes.number.isRequired,
  t: PropTypes.func,
};

export default flowRight(
  withTranslate,
  withCardBorderWidth,
)(PostPageSidebarStats);
