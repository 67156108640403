import { FOCUS_COMMENT_BOX_DEEP_LINK_ID } from '@wix/communities-forum-client-commons/src';

export function getDeepLinkParams(deepLinkData) {
  let commentToFocusId = null;
  let parentId = null;
  let repliesPage = null;
  let focusCommentBox = false;
  if (deepLinkData) {
    const deepLinkParts = deepLinkData.split('-');
    if (deepLinkParts[0] === FOCUS_COMMENT_BOX_DEEP_LINK_ID) {
      focusCommentBox = true;
    } else {
      commentToFocusId = deepLinkParts[0];
    }
    parentId = deepLinkParts[1] ? deepLinkParts[1] : null;
    repliesPage = deepLinkParts[2] ? parseInt(deepLinkParts[2], 10) : null;
  }

  return { commentToFocusId, parentId, repliesPage, focusCommentBox };
}
