import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import Link from './internal-link';
import { getCategory } from '../../../common/selectors/categories-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

const PostLink = React.memo(({ category, className, post, children, ...props }) => (
  <Link className={className} to={`/${category?.slug}/${post?.slug}`} {...props}>
    {children}
  </Link>
));

PostLink.propTypes = {
  post: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const mapRuntimeToProps = (state, { post }) => ({
  category: getCategory(state, post.categoryId),
});

export default connect(mapRuntimeToProps, [REDUCERS.CATEGORIES])(PostLink);
