import {
  CommentReactionsConfig,
  ReactionsConfig,
  ReplyReactionsConfig,
} from '@wix/comments-ooi-client';
import {
  EmotionsConfig,
  LikesConfig,
  NoReactions,
  VotingConfig,
  MainReactionName,
  ReactionName,
} from '@wix/comments-ooi-client/reaction-types';
import { unreachable } from '../../../utils/ts-utils';

type ForumAdditionalReactions =
  | 'wow'
  | 'sad'
  | 'haha'
  | 'angry'
  | 'clap'
  | 'thumbs_up'
  | 'thinking'
  | 'love'
  | 'smile';

type ForumMainReactionName = 'love' | 'thumbs_up';

type CommentingType = 'none' | 'reaction' | 'vote';

type CommentsReactionsConfigProps = Partial<{
  commentingType: CommentingType;
  mainCommentReaction: {
    type: ForumMainReactionName;
  };
  additionalCommentReactions: {
    type: ForumAdditionalReactions;
  }[];
}>;

const MAX_ADDITIONAL_REACTIONS = 5;

export const resolveReactionsConfig = ({
  commentingType,
  mainCommentReaction: mainCommentReactionIcon,
  additionalCommentReactions,
}: CommentsReactionsConfigProps): ReactionsConfig => {
  additionalCommentReactions = additionalCommentReactions || [];
  const isAdditionalReactionsAvailable = additionalCommentReactions.length > 0;

  const reactionType = (() => {
    const defaultReactionType = isAdditionalReactionsAvailable ? 'emotions' : 'likes';
    switch (commentingType) {
      case 'none':
        return 'none';
      case 'vote':
        return 'votes';
      case 'reaction':
      case undefined:
        return defaultReactionType;
      default:
        unreachable(commentingType);
        return defaultReactionType;
    }
  })();

  const reactionsConfig: {
    none: NoReactions;
    likes: LikesConfig;
    votes: VotingConfig;
    emotions: EmotionsConfig;
  } = {
    none: { type: 'none' },
    likes: { type: 'likes', mainEmotion: getMainReactionName(mainCommentReactionIcon?.type) },
    votes: { type: 'votes' },
    emotions: {
      type: 'emotions',
      mainEmotion: getMainReactionName(mainCommentReactionIcon?.type),
      emotions: (() => {
        const additionalReactions = additionalCommentReactions.map((reaction) =>
          getReactionName(reaction.type),
        );
        additionalReactions.length = Math.min(additionalReactions.length, MAX_ADDITIONAL_REACTIONS);
        return additionalReactions as EmotionsConfig['emotions'];
      })(),
    },
  };

  const commentReactions: CommentReactionsConfig = reactionsConfig[reactionType];
  const replyReactions: ReplyReactionsConfig =
    reactionType === 'votes'
      ? isAdditionalReactionsAvailable
        ? reactionsConfig.emotions
        : reactionsConfig.likes
      : reactionsConfig[reactionType];

  return { commentReactions, replyReactions };
};

const getMainReactionName = (reaction?: ForumAdditionalReactions): MainReactionName => {
  const defaultReaction = 'heart';

  switch (reaction) {
    case 'love':
      return 'heart';
    case 'thumbs_up':
      return 'thumbsup';
    case undefined:
      return defaultReaction;
    default:
      return defaultReaction;
  }
};

const getReactionName = (reactionName?: ForumAdditionalReactions): ReactionName | undefined => {
  switch (reactionName) {
    case 'angry':
    case 'clap':
    case 'sad':
    case 'thinking':
      return reactionName;
    case 'thumbs_up':
      return 'thumbsup';
    case 'love':
      return 'heart';
    case 'smile':
      return 'smiley';
    case 'wow':
      return 'surprised';
    case 'haha':
      return 'lol';
    case undefined:
      return undefined;
    default:
      console.error(unreachable(reactionName));
      return undefined;
  }
};
