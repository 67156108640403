import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withTranslate from '../../../common/components/with-translate/with-translate';
import CardHeaderLeftSide from './card-header-left-side';
import UserAvatarImage from '../user-avatar-image';
import ProfileLink from '../link/profile-link';
import { PinIcon } from '../icons/pin-icon';
import MemberCardAsync from '../../containers/member-card-async';
import styles from './post-page-card-header.scss';
import AvatarImage from '../avatar-image';
import { MoreIconCentered } from '../icons/more-icon';
import MoreButtonMobile from '../more-button-mobile/more-button-mobile';
import { MODAL_TYPE_POST_ACTIONS } from '../modals/action-sheet/action-sheet-modal-types';

class PostPageCardHeader extends Component {
  renderPinIcon = () => (
    <div data-hook="pin-icon">
      <PinIcon
        className="icon-secondary-fill"
        aria-label={this.props.t('post-list-item.pinned-post')}
        isPinned
      />
    </div>
  );

  renderActionsButton = () => {
    const { post } = this.props;

    return (
      <MoreButtonMobile
        center
        post={post}
        Icon={MoreIconCentered}
        type={MODAL_TYPE_POST_ACTIONS}
        dataHook="post-actions-button"
      />
    );
  };

  render() {
    const { post } = this.props;
    const user = post.owner || {};
    const date = post.createdDate;

    return (
      <div className={styles.header}>
        <div className={styles.headerLeftSide}>
          <ProfileLink user={user}>
            <MemberCardAsync
              className={styles.avatar}
              viewedMemberId={user.siteMemberId}
              viewedMemberSlug={user.slug}
            >
              <UserAvatarImage user={user} type={AvatarImage.POST_PAGE_HEADER} />
            </MemberCardAsync>
          </ProfileLink>
          <CardHeaderLeftSide user={user} date={date} />
        </div>
        {post.isPinned && <div className={styles.headerRightSide}>{this.renderPinIcon()}</div>}
        <div className={styles.headerRightSide}>{this.renderActionsButton()}</div>
      </div>
    );
  }
}

PostPageCardHeader.propTypes = {
  post: PropTypes.object.isRequired,
};

export default flowRight(withTranslate)(PostPageCardHeader);
