import React, { Component } from 'react';
import { flowRight } from 'lodash';
import { TFunction, HttpClient } from '@wix/yoshi-flow-editor';
import { WixComments, Configuration, HeaderTitleText } from '@wix/comments-ooi-client';
import { connect } from '../../../common/components/runtime-context';
import { OwnerSmallIcon } from '../../components/icons/owner-small-icon';
import withAuth from '../../hoc/with-auth';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { withRcePluginsConfig, PluginsConfig } from '../../hoc/with-rce-plugins-config';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withButtonColor from '../../hoc/with-button-color';
import withSettingsColor from '../../hoc/with-settings-color';
import {
  APP_TEXT_COLOR_PATH,
  VIDEO_UPLOAD_SIZE_LIMIT_MB,
  MARKED_COMMENT_ICON_TYPES,
  MARKED_COMMENT_TYPES,
  EXPERIMENT_LOAD_MAX_COMMENTS_INITIALLY,
} from '@wix/communities-forum-client-commons';
import { isSite } from '../../../common/store/basic-params/basic-params-selectors';
import { buildDeepLink, buildDeepLinkUrl } from '../../services/build-deep-comment-url';
import { getContextToken } from '../../services/context-token';
import withRelativeTimeFormatting from '../../hoc/with-relative-time-formatting';
import { buildCssOverride } from './css-override';
import { getMarkedCommentLabel } from '../../services/get-marked-comment-label';
import { getBestAnswerIcon } from '../../services/get-best-answer-icon';

import {
  getIsCommentsHeaderCountEnabled,
  getIsCommentsHeaderSortEnabled,
  getIsCommentsListAvatarEnabled,
  getIsCommentsListPublishDateEnabled,
  getIsCommentsListRepliedToEnabled,
  getIsUploadLimiterEnabled,
  getUploadSizeLimit,
} from '../../selectors/app-settings-selectors';
import { getIsQaMode } from '../../../common/store/location/location-selectors';
import { resolveReactionsConfig } from './comments-reactions-config.util';
import { useTranslate } from '../../../common/api-providers/use-translate';
import { RootState } from '../../types/store-types';
import { ForumControllerActions } from '../../types/common-types';
import { Category, Post } from '../../types';
import { getPaginationConfig } from '../../constants/comments-pagination-config';
import { buildCommentsPluginConfig } from './plugins';

type MarkedCommentConfig = {
  icon: keyof typeof MARKED_COMMENT_ICON_TYPES;
  type: keyof typeof MARKED_COMMENT_TYPES;
};

const mapConfigToCommentsProps = (config: MarkedCommentConfig, t: TFunction) => {
  const typeLabel = getMarkedCommentLabel(config, t);
  let icon;
  if (config) {
    const Icon = getBestAnswerIcon(config.icon);
    icon = Icon ? { type: 'CUSTOM', icon: Icon } : { type: 'NONE' };
  }

  return (
    typeLabel && {
      badgeLabel: typeLabel,
      markActionLabel: t('comment-marked-type.mark-as-type-label', { label: typeLabel }),
      icon,
    }
  );
};

type PostPageWixCommentsProps = {
  post: Post;
  markedCommentConfig: MarkedCommentConfig;
  pluginsConfig: PluginsConfig;
  filterEnabledPlugins(arg: string[]): string[];
  paginationConfig: Configuration['pagination'];
  formatRelativeTime: Configuration['formatRelativeTime'];
  contentTruncationLimit: number;
  style: any;
  cardBackgroundColor: string;
  textColor: string;
  buttonColor: string;
  isLiveSite: boolean;
  commentId?: string;
  generateLink: (commentId: string) => string;
  hideHeaderDivider: boolean;
  category: Category;
  screenShotTest: boolean;
  isOwner: boolean;
  isUploadLimiterEnabled: boolean;
  uploadSizeLimit: number;
  deepLinkUrl: string;
  hideReplyingTo: boolean;
  hidePublishDate: boolean;
  hideAvatars: boolean;
  hideSorting: boolean;
  hideCommentsCount: boolean;
};

const Comments: React.FC<PostPageWixCommentsProps> = React.memo(
  ({
    post,
    markedCommentConfig,
    pluginsConfig,
    paginationConfig,
    formatRelativeTime,
    contentTruncationLimit,
    style,
    cardBackgroundColor,
    textColor,
    buttonColor,
    isLiveSite,
    commentId,
    hideHeaderDivider,
    category,
    screenShotTest,
    isOwner,
    isUploadLimiterEnabled,
    uploadSizeLimit,
    deepLinkUrl,
    hideReplyingTo,
    hidePublishDate,
    hideAvatars,
    hideSorting,
    hideCommentsCount,
    filterEnabledPlugins,
  }) => {
    const { t } = useTranslate();
    const httpClient = React.useMemo(() => new HttpClient(), []);

    const markLabelsConfig = React.useMemo(
      () => mapConfigToCommentsProps(markedCommentConfig, t),
      [markedCommentConfig, t],
    );

    const commentsPluginConfig = React.useMemo(
      () => buildCommentsPluginConfig({ pluginsConfig, filterEnabledPlugins }),
      [pluginsConfig, filterEnabledPlugins],
    );

    const ctxFields = React.useMemo(() => getContextToken(post._id), [post._id]);
    const formatRelativeTimeFn = React.useMemo(
      () => (time: Date) => formatRelativeTime && formatRelativeTime(time),
      [formatRelativeTime],
    );

    const ricosTheme = React.useMemo(
      () => ({
        theme: {
          palette: {
            bgColor: cardBackgroundColor,
            textColor,
            actionColor: buttonColor,
          },
        },
        cssOverride: buildCssOverride(style),
      }),
      [cardBackgroundColor, textColor, buttonColor, style],
    );

    const deepLinkConfig = React.useMemo(
      () =>
        isLiveSite
          ? {
              commentId,
              generateLink: (id: string) => buildDeepLink(deepLinkUrl, id, post._id),
            }
          : undefined,
      [isLiveSite, commentId, post._id],
    );

    const reactionsConfig = React.useMemo(() => {
      return resolveReactionsConfig({
        commentingType: post.commentingType ?? undefined,
        mainCommentReaction: (category.mainCommentReaction as any) ?? undefined,
        additionalCommentReactions: (category.additionalCommentReactions as any) ?? [],
      });
    }, [post.commentingType, category]);

    const fileUploadSizeLimit = isOwner
      ? undefined
      : isUploadLimiterEnabled
      ? uploadSizeLimit
      : VIDEO_UPLOAD_SIZE_LIMIT_MB;

    const headerTitleText: HeaderTitleText | undefined = React.useMemo(() => {
      if (!hideCommentsCount && !category.commentsHeaderText) {
        return undefined;
      }

      return (commentsCount: number) => {
        if (hideCommentsCount) {
          return category.commentsHeaderText || t('comments.title');
        }

        if (!category.commentsHeaderText) {
          return t('comments.title-with-count', { count: commentsCount });
        }

        return t('comments.title-placeholder', {
          count: commentsCount,
          title: category.commentsHeaderText,
        });
      };
    }, [hideCommentsCount, category.commentsHeaderText, t]);

    return (
      <WixComments
        httpClient={httpClient}
        markLabels={markLabelsConfig}
        hideZeroCommentsEmptyState
        pluginConfig={commentsPluginConfig}
        fileUploadSizeLimit={fileUploadSizeLimit}
        resourceId={post._id}
        deepLink={deepLinkConfig}
        ctxFields={ctxFields}
        pagination={paginationConfig}
        formatRelativeTime={formatRelativeTimeFn}
        isLocked={post.isCommentsDisabled}
        contentTruncationLimit={contentTruncationLimit}
        roleIcons={ROLE_ICONS}
        ricosTheming={ricosTheme}
        t={t}
        hideHeaderDivider={hideHeaderDivider}
        reactions={reactionsConfig}
        screenshotMode={screenShotTest}
        blackAndWhiteModals
        _showReportAction
        disableResourceAutoFetching={true}
        disableResourceCleanup={true}
        hideReplyingTo={hideReplyingTo}
        hidePublishDate={hidePublishDate}
        hideAvatars={hideAvatars}
        hideSorting={hideSorting}
        repliesCreation={category.disableRepliesCreation ? 'DISABLED' : undefined}
        headerTitleText={headerTitleText}
        publishButtonText={category.commentsPublishButtonText}
        placeholderText={category.commentsPlaceholderText}
      />
    );
  },
);

class PostPageWixComments extends Component<PostPageWixCommentsProps, { isInErrorState: boolean }> {
  state = { isInErrorState: false };
  componentDidCatch(error: any) {
    // internal comments error handler should catch errors, but there still can be cases where error bubbles up
    this.setState({ isInErrorState: true });
    console.error(error);
  }

  render() {
    if (this.state.isInErrorState) {
      return null;
    }

    return <Comments {...this.props} />;
  }
}

const ROLE_ICONS = {
  OWNER: OwnerSmallIcon,
  CONTRIBUTOR: OwnerSmallIcon,
};

const mapRuntimeToProps = (
  state: RootState,
  ownProps: any,
  actions: ForumControllerActions,
  host: any,
) => {
  return {
    style: host.style,
    isLiveSite: isSite(state),
    deepLinkUrl: buildDeepLinkUrl(state),
    paginationConfig:
      ownProps.paginationConfig ||
      getPaginationConfig(
        ownProps.post,
        ownProps.category?.commentsOrdering,
        state.experiments[EXPERIMENT_LOAD_MAX_COMMENTS_INITIALLY] === 'true',
      ),
    isUploadLimiterEnabled: getIsUploadLimiterEnabled(state, host.style),
    uploadSizeLimit: getUploadSizeLimit(state, host.style),
    hideReplyingTo: !getIsCommentsListRepliedToEnabled(state, host.style),
    hidePublishDate: !getIsCommentsListPublishDateEnabled(state, host.style),
    hideAvatars: !getIsCommentsListAvatarEnabled(state, host.style),
    hideSorting: !getIsCommentsHeaderSortEnabled(state, host.style),
    hideCommentsCount: !getIsCommentsHeaderCountEnabled(state, host.style),
    screenShotTest: getIsQaMode(state),
    siteColors: undefined,
  };
};

export default flowRight(
  withRelativeTimeFormatting,
  withCardBackgroundColor,
  withButtonColor,
  withSettingsColor({
    path: APP_TEXT_COLOR_PATH,
    propName: 'textColor',
    siteColorFallback: 'color-5',
  }),
  withRcePluginsConfig,
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps),
)(PostPageWixComments);
